import { GetEnvConfiguration } from '../services/configurationService';
import {
  IPartnersViewMessage,
  ISetContextMessage,
  PartnersViewMessageType,
  IScrollChangeMessage,
  ISizeChangeMessage
} from './partnersViewInboundMessages';

export interface IInboundMessagesCallbacks {
  onSetContextMessage(context: ISetContextMessage, sender: InboundMessagesHandler): void;
  onScrollChange(xOffset: number, yOffset: number, sender: InboundMessagesHandler): void;
  onSizeChange(width: number, height: number, frameLeft: number, frameTop: number, sender: InboundMessagesHandler): void;
  onReceiveValidFrameOrigin(origin: string): void;
}

/*
  Handle messages comming from partners host(AppSource).
 */
export class InboundMessagesHandler {
  active = false;
  callbacksHandlers: IInboundMessagesCallbacks;
  contextMessageCount = 0;

  constructor(callbacksHandlers: IInboundMessagesCallbacks) {
    this.callbacksHandlers = callbacksHandlers;
  }

  public startHandleInboundMessages = () => {
    if (!this.active) {
      this.active = true;
      window.addEventListener('message', this.handleMessage);
    }
  };

  public stopHandleInboundMessages = () => {
    this.active = false;
    window.removeEventListener('message', this.handleMessage);
  };

  private handleMessage = (e: any) => {
    const validOrigins = GetEnvConfiguration().VALID_MESSAGE_ORIGINS;
    if (validOrigins && validOrigins.length) {
      if (validOrigins.indexOf(e.origin) === -1) {
        return;
      }
      // Set the origin as the default target from now on
      this.callbacksHandlers.onReceiveValidFrameOrigin(e.origin);
    }

    const partnerViewMessage: IPartnersViewMessage = JSON.parse(e.data);
    if (partnerViewMessage.messageType === PartnersViewMessageType.SetContext && this.callbacksHandlers.onSetContextMessage) {
      this.contextMessageCount++;
      this.callbacksHandlers.onSetContextMessage(partnerViewMessage as ISetContextMessage, this);
    } else if (partnerViewMessage.messageType === PartnersViewMessageType.ScrollChange && this.callbacksHandlers.onScrollChange) {
      const scrollChangeMessage: IScrollChangeMessage = partnerViewMessage as IScrollChangeMessage;
      this.callbacksHandlers.onScrollChange(scrollChangeMessage.xOffset, scrollChangeMessage.yOffset, this);
    } else if (partnerViewMessage.messageType === PartnersViewMessageType.SizeChange && this.callbacksHandlers.onSizeChange) {
      const sizeChangeMessage: ISizeChangeMessage = partnerViewMessage as ISizeChangeMessage;
      this.callbacksHandlers.onSizeChange(
        sizeChangeMessage.width,
        sizeChangeMessage.height,
        sizeChangeMessage.frameLeft,
        sizeChangeMessage.frameTop,
        this
      );
    }
  };

  public getContextMessageCount = (): number => {
    return this.contextMessageCount;
  };
}
